import React from "react";
import { NavLink } from "react-router-dom";
import { useStore } from "../store/Store";

function Note() {
  const store = useStore();
  //const [artNote, setArtNote] = useState("");

  const handleNewNote = (e) => {
    store.addNote(e.target.value);
  };

  // const handleAddArticleNote = () => {
  //   //store.addArticleNote({ item: 1, note: artNote });
  //   store.addArticleNote([...store.articleNote, { item: 1, note: artNote }]);
  // };

  return (
    <div className="container">
      <div className="complete-content">
        <h1>Notes</h1>
        <textarea
          className="note-textarea"
          name="note"
          value={store.note}
          onChange={handleNewNote}
          rows="5"
          cols="45"
          placeholder="General Meeting Notes..."
        ></textarea>
        <div className="router-link">
          <NavLink to="/summary">View Summary</NavLink>
        </div>
      </div>
    </div>
  );
}

export default Note;
