import React, { useState, useEffect } from "react";
import { useStore } from "../store/Store";
import "../App.css";

function Modal({ setModalShow, modalItem }) {
  const [company, setCompany] = useState("");
  const [article, setArticle] = useState("");
  const [thickness, setThickness] = useState("");
  const [color, setColor] = useState("");

  useEffect(() => {
    getReadableName(store.articles[modalItem]);
  }, []);

  function findArticleNote(i) {
    const myNote = store.articleNote.find((n) => n.item === i);
    if (myNote) {
      return myNote.note;
    }
    return null;
  }
  function findTookSwatch(i) {
    const myNote = store.articleNote.find((n) => n.item === i);
    if (myNote) {
      return myNote.took;
    }
    return false;
  }
  function findRequestSwatch(i) {
    const myNote = store.articleNote.find((n) => n.item === i);
    if (myNote) {
      return myNote.request;
    }
    return false;
  }
  const store = useStore();
  const [itemNote, setItemNote] = useState(findArticleNote(modalItem) || "");
  const [tookSwatch, setTookSwatch] = useState(findTookSwatch(modalItem));
  const [requestSwatch, setRequestSwatch] = useState(
    findRequestSwatch(modalItem)
  );

  function getReadableName(data) {
    const matCompany = data.split("|")[0];
    setCompany(matCompany.substring(3).toUpperCase());
    const matName = data.split("|")[1];
    setArticle(matName.toUpperCase().replaceAll("-", " "));
    const swatchDetails = data.split(">")[1];
    setColor(swatchDetails.split("-")[0]);
    setThickness(swatchDetails.slice(-9));
    return matName.toUpperCase().replaceAll("-", " ") + " > " + swatchDetails;
  }

  const handleDeleteArticle = () => {
    const article = store.articles[modalItem];
    const newArticleArray = store.articles.filter((item) => item !== article);
    store.addArticles(newArticleArray);
    const currentNote = store.articleNote;
    const notesIndex = currentNote.findIndex((n) => n.item === modalItem);
    if (notesIndex !== -1) {
      currentNote.splice(notesIndex, 1);
      store.addArticleNote(currentNote);
    }
    setModalShow(false);
    //console.log(newArticleArray, notesIndex, currentNote);
  };
  const handleUpdateNote = () => {
    const current = store.articleNote;
    const getIndex = current.findIndex((n) => n.item === modalItem);
    if (getIndex !== -1) {
      const note = {
        item: modalItem,
        note: itemNote,
        took: tookSwatch,
        request: requestSwatch,
      };
      current.splice(getIndex, 1, note);
      store.addArticleNote(current);
    } else {
      store.addArticleNote([
        ...store.articleNote,
        {
          item: modalItem,
          note: itemNote,
          took: tookSwatch,
          request: requestSwatch,
        },
      ]);
    }
    setModalShow(false);
  };

  return (
    <div className="modal-shade">
      <div className="modal-content">
        <div className="modal-close" onClick={() => setModalShow(false)}>
          Close
        </div>
        <div className="modal-header">
          <h4>{company}</h4>
          <h1>{article}</h1>
          <h5>{thickness}</h5>
          <h4>{color}</h4>
        </div>
        <div className="checkbox-wrapper">
          <table>
            <tbody>
              <tr>
                <td className="table-checkbox-label">Request Swatch</td>
                <td className="table-checkbox">
                  <input
                    type="checkbox"
                    onChange={() => setRequestSwatch(!requestSwatch)}
                    checked={requestSwatch}
                  />
                </td>
              </tr>
              <tr>
                <td className="table-checkbox-label">Took Swatch</td>
                <td className="table-checkbox">
                  <input
                    type="checkbox"
                    onChange={() => setTookSwatch(!tookSwatch)}
                    checked={tookSwatch}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <textarea
          className="note-textarea"
          value={itemNote}
          onChange={(e) => setItemNote(e.target.value)}
          rows="5"
          cols="45"
          placeholder="Add Note..."
        ></textarea>
        <button onClick={handleUpdateNote}>Done adding</button>
        <button onClick={handleDeleteArticle}>Delete this article</button>
      </div>
    </div>
  );
}

export default Modal;
