import React, { useEffect, useState } from "react";

function Mcs() {
  const [mcs, setMcs] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetch("https://node.matlib.net/brand/", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setMcs(data);
        setFiltered(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  //   useDebouncedEffect(
  //     () => {
  //       if (search.length >= 3) {
  //         const formatSearch = search.replace(" ", "-").toLocaleLowerCase();
  //         const getBySearch = mcs.filter((art) => art.slug === formatSearch);
  //         setFiltered(getBySearch);
  //         console.log(formatSearch);
  //       }
  //     },
  //     [search],
  //     1000
  //   );

  useEffect(() => {
    if (search.length >= 3) {
      const formatSearch = search.replace(" ", "-").toLocaleLowerCase();
      const getBySearch = mcs.filter(({ pdm_material_name }) =>
        pdm_material_name.includes(search.toUpperCase())
      );
      setFiltered(getBySearch);
      console.log(formatSearch);
    }
    return () => {
      setFiltered(mcs);
    };
  }, [search]);

  return (
    <div className="container">
      <input
        className="whoInput"
        type="text"
        value={search}
        placeholder="Search MCS"
        onChange={(e) => setSearch(e.target.value)}
      />
      {search.length ? (
        <button className="button-small" onClick={() => setSearch("")}>
          clear
        </button>
      ) : null}
      <div className="output">
        <ul className="article-list">
          {filtered.length
            ? filtered.map((art) => (
                <li key={art.pcx_material_number}>
                  <div className="mcs-info">
                    <h4>{art.pdm_material_name}</h4>
                    <h5>PCX#:{art.pcx_material_number}</h5>
                    <h5>MCS#:{art.mcs_number}</h5>
                  </div>
                </li>
              ))
            : "loading"}
        </ul>
      </div>
    </div>
  );
}

export default Mcs;
