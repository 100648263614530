import React, { useEffect, useState } from "react";
import { useStore } from "../store/Store";
import { useNavigate } from "react-router-dom";

function StoredMeetings() {
  const store = useStore();
  const navigate = useNavigate();
  const [stored, setStored] = useState([]);
  const [formatted, setFormatted] = useState([]);
  useEffect(() => {
    fetch(`${store.URL}/mail/showlist`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setStored(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    function groupBy(objectArray, property) {
      return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        // Add object to list for given key's value
        acc[key].push(obj);
        return acc;
      }, {});
    }
    const groupedCustomer = groupBy(stored, "customer");
    setFormatted(groupedCustomer);
  }, [stored]);

  const customerGroup = Object.values(formatted);

  const handleRestore = (v) => {
    const restoreArticles = formatted[v].map((items) => items.rawArticleFormat);
    const restoreAllRecords = formatted[v].map((items) => items);
    store.addArticles(restoreArticles);
    store.addWho(v);
    store.addNote(restoreAllRecords[0].meetingNote);
    const getArtNotes = restoreAllRecords
      .map((element) => element.articleNotes)
      .map((b, i) => [
        { item: i, note: b.note, request: b.request, took: b.took },
      ])
      .flat();
    store.addArticleNote(getArtNotes);
    navigate("/summary");
  };
  function getDate(date) {
    const d = new Date(date);
    return d.toLocaleString();
  }
  return (
    <div className="container">
      <h4>
        {Object.values(formatted).length} Saved Meetings: {store.title}
      </h4>
      {Object.keys(formatted).length
        ? Object.keys(formatted).map((k, i) => (
            <div key={k} className="stored-group">
              <h3>
                {k} {customerGroup[i].length} items
              </h3>
              <h4>{customerGroup[i][0].meetingNote}</h4>
              <ul className="article-list">
                {customerGroup[i].map((item, i) => (
                  <li key={item + i}>
                    {item.article} {item.color} {item.thickness}
                  </li>
                ))}
              </ul>
              <div className="restore-button-frame">
                <div>Date: {getDate(customerGroup[i][0].date)}</div>
                <button
                  className="button-small"
                  onClick={() => handleRestore(k)}
                >
                  Restore
                </button>
              </div>
            </div>
          ))
        : null}
    </div>
  );
}

export default StoredMeetings;
