import React, { useState } from "react";
import { useStore } from "../store/Store";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import {
  doubleChevronIcon,
  elipsisIcon,
  noteIcon,
  trash,
} from "../icons/Icons";

function Complete() {
  const store = useStore();
  //const URL_Mail = "http://localhost:5001/mail/send";
  const URL_Mail = `${store.URL}/mail/send`;
  const [modalShow, setModalShow] = useState(false);
  const [modalItem, setModalItem] = useState(null);

  const navigate = useNavigate();

  // const getTheDate = (timeStamp) => {
  //   const dateFormat = new Date(timeStamp);
  //   return dateFormat.toDateString();
  // };
  // console.log(
  //   "final",
  //   store.articleNote.length ? store.articleNote[0].note : "fucker"
  // );
  function getReadableName(data, i) {
    const matCompany = data.split("|")[0];
    const company = matCompany.substring(3).toUpperCase();
    const matName = data.split("|")[1];
    const article = matName.toUpperCase().replaceAll("-", " ");
    const swatchDetails = data.split(">")[1];
    const color = swatchDetails.split("-")[0];
    const thickness = swatchDetails.slice(-9);
    return (
      <>
        {i + 1}. <span className="company-italic">{company}</span> {article}
        <span className="details">
          {" "}
          {color} {thickness}
        </span>
      </>
    );
  }

  function findArticleNote(i) {
    const myNote = store.articleNote.find((n) => n.item === i);
    if (myNote) {
      if (myNote.note || myNote.took || myNote.request) {
        return (
          <div className="note-box">
            {myNote.note ? (
              <span className="article-note">
                {doubleChevronIcon} {myNote.note}
              </span>
            ) : null}
            <div className="notification-box">
              {myNote.took ? (
                <span className="article-note notification"> Took Swatch</span>
              ) : null}
              {myNote.request ? (
                <span className="article-note alert"> Request Swatch</span>
              ) : null}
            </div>
          </div>
        );
      }
    }
    return null;
  }

  const handleUpdate = () => {
    const data = store.articles.map((item, i) => {
      const who = store.who;
      const rawArticleFormat = item;
      const matCompany = item.split("|")[0];
      const company = matCompany.substring(3);
      const matName = item.split("|")[1];
      const article = matName.toUpperCase().replaceAll("-", " ");
      const swatchDetails = item.split(">")[1];
      const color = swatchDetails.split("-")[0];
      const thickness = swatchDetails.slice(-9);
      const getNote = store.articleNote.find((n) => n.item === i);
      if (getNote) {
        const articleNote = getNote;
        const note = articleNote.note;
        const took = articleNote.took;
        const request = articleNote.request;
        //console.log(articleNote.took, articleNote.request, articleNote.note);
        return [
          {
            who,
            company,
            article,
            color,
            thickness,
            note,
            took,
            request,
            rawArticleFormat,
          },
        ];
      }
      //console.log(articleNote.took, articleNote.request, articleNote.note);
      return [
        {
          who,
          company,
          article,
          color,
          thickness,
          note: "",
          took: false,
          request: false,
          rawArticleFormat,
        },
      ];
    });

    fetch(`${store.URL}/mail/sendcheck`, {
      method: "POST",
      body: JSON.stringify({
        data,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleEmail = () => {
    const title = store.title;
    const who = store.who;
    if (!who) {
      navigate("/");
      return;
    }
    const todayDate = new Date();
    const date = todayDate.toDateString();
    const notes = store.note;
    const readable = store.articles.map((item, i) => {
      const matCompany = item.split("|")[0];
      const company = matCompany.substring(3);
      const matName = item.split("|")[1];
      const article = matName.toUpperCase().replaceAll("-", " ");
      const swatchDetails = item.split(">")[1];
      const getNote = store.articleNote.find((n) => n.item === i);
      const isNote = getNote;
      if (isNote) {
        return `${company} ${article} ${swatchDetails} > ${
          isNote.note ? isNote.note : ""
        } ${isNote.took ? "took swatch" : ""}  ${
          isNote.request ? "request swatch" : ""
        } `;
      } else {
        return `${company} ${article} ${swatchDetails}`;
      }

      // return `${company} ${article} ${swatchDetails} ${getNote}`;
    });
    const data = store.articles.map((item, i) => {
      const who = store.who;
      const rawArticleFormat = item;
      const matCompany = item.split("|")[0];
      const company = matCompany.substring(3);
      const matName = item.split("|")[1];
      const article = matName.toUpperCase().replaceAll("-", " ");
      const swatchDetails = item.split(">")[1];
      const color = swatchDetails.split("-")[0];
      const thickness = swatchDetails.slice(-9);
      const getNote = store.articleNote.find((n) => n.item === i);
      if (getNote) {
        const articleNote = getNote;
        const note = articleNote.note;
        const took = articleNote.took;
        const request = articleNote.request;
        //console.log(articleNote.took, articleNote.request, articleNote.note);
        return [
          {
            who,
            company,
            article,
            color,
            thickness,
            note,
            took,
            request,
            rawArticleFormat,
          },
        ];
      }
      //console.log(articleNote.took, articleNote.request, articleNote.note);
      return [
        {
          who,
          company,
          article,
          color,
          thickness,
          note: "",
          took: false,
          request: false,
          rawArticleFormat,
        },
      ];
    });
    fetch(URL_Mail, {
      method: "POST",
      body: JSON.stringify({
        readable,
        data,
        title,
        date,
        who,
        notes,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        store.removeAllArticles();
        console.log(res);
        navigate("/");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleSelectedItem = (item) => {
    setModalShow(true);
    setModalItem(item);
  };
  const handleRemoveNote = () => {
    store.addNote("");
  };
  const handleReset = () => {
    store.removeAllArticles();
    navigate("/");
  };

  return (
    <>
      {modalShow ? (
        <Modal
          setModalShow={setModalShow}
          modalItem={modalItem}
          //tookSwatch={tookSwatch}
          //readable={readable}
          //setTookSwatch={setTookSwatch}
        />
      ) : null}
      <div className="container">
        <div className="complete-content">
          <h1>{store.title}</h1>
          <h2>
            {store.who ? (
              store.who
            ) : (
              <div onClick={() => navigate("/")}>Add meeting name</div>
            )}
          </h2>
          <ul className="note-list">
            {store.articles.map((item, i) => (
              <li key={item}>
                <div className="list-text">
                  {getReadableName(item, i)}
                  {store.articleNote.length ? findArticleNote(i) : ""}
                </div>
                <div
                  style={{
                    minWidth: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className={
                    findArticleNote(i) ? "selectedItem active" : "selctedItem"
                  }
                >
                  <span onClick={() => handleSelectedItem(i)}>
                    {elipsisIcon}
                  </span>
                </div>
              </li>
            ))}
          </ul>
          <div className="notes-heading">
            <h4>Notes:</h4>
          </div>
          <div className="notes-window">
            {store.note ? (
              <div className="notes">
                <div className="notes-text">
                  <h4>{store.note}</h4>
                </div>
                <div className="notes-button-group">
                  <div className="notes-button" onClick={handleRemoveNote}>
                    {trash}
                  </div>
                  <div
                    className="notes-button"
                    onClick={() => navigate("/note")}
                  >
                    {noteIcon}
                  </div>
                </div>
              </div>
            ) : (
              <div className="notes">
                <h4>No Notes</h4>
                <div className="notes-button" onClick={() => navigate("/note")}>
                  {noteIcon}
                </div>
              </div>
            )}
          </div>
          <button onClick={handleEmail}>Send this out</button>
          <button onClick={handleReset}>Reset Everything</button>
          <button onClick={handleUpdate}>Check if exists</button>
        </div>
      </div>
    </>
  );
}

export default Complete;
