import { create } from "zustand";

const articlesLocal = JSON.parse(localStorage.getItem("articles"));

export const useStore = create((set) => ({
  title: "NW SHOW",
  //URL: "http://localhost:5001",
  URL: "https://node.matlib.net",
  who: "",
  addWho: (value) => set({ who: value }),
  articles: articlesLocal || [],
  addArticles: (value) => {
    localStorage.setItem("articles", JSON.stringify(value));
    set({ articles: value });
  },
  articleNote: [],
  addArticleNote: (value) => {
    set({ articleNote: value });
  },
  note: "",
  addNote: (value) => set({ note: value }),
  removeAllArticles: () => {
    localStorage.removeItem("articles");
    set({ who: "" });
    set({ note: "" });
    set({ articles: [] });
    set({ articleNote: [] });
  },
}));
