import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { cogIcon } from "../icons/Icons";

function Menu() {
  const [trayOpen, setTrayOpen] = useState(false);
  return (
    <div className={`nav-bar ${trayOpen ? "open" : null}`}>
      <div className="button-group">
        <NavLink to="/" onClick={() => setTrayOpen(false)}>
          Start
        </NavLink>
        <NavLink to="/scan" onClick={() => setTrayOpen(false)}>
          Scan
        </NavLink>
        <NavLink to="/note" onClick={() => setTrayOpen(false)}>
          Note
        </NavLink>
        <NavLink to="/summary" onClick={() => setTrayOpen(false)}>
          Summary
        </NavLink>
      </div>
      <span className="setting-button" onClick={() => setTrayOpen(!trayOpen)}>
        {cogIcon}
      </span>
      <div className="nav-bar-hidden-content">
        <h4 className="setting-header">{cogIcon} SETTINGS </h4>
        <div className="hidden-content-list">
          <NavLink to="/stored" onClick={() => setTrayOpen(false)}>
            Stored
          </NavLink>
          <NavLink to="/mcs" onClick={() => setTrayOpen(false)}>
            MCS
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Menu;
