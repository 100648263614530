import React from "react";
import { useStore } from "../store/Store";
import { NavLink } from "react-router-dom";
import { viewIcon, noteIcon } from "../icons/Icons";

function Landing() {
  const store = useStore();

  const handleWho = (e) => {
    store.addWho(e.target.value);
  };
  return (
    <div className="container">
      <div className="landing-content">
        <h1>{store.title}</h1>

        <div className="link-row">
          <input
            className="whoInput"
            type="text"
            value={store.who}
            onChange={handleWho}
            placeholder="what's happening"
          />
        </div>
        <div className="link-row">
          <div className="router-link">
            <NavLink to="/scan">{viewIcon} Scan</NavLink>
          </div>
          <div className="router-link">
            <NavLink to="/note">{noteIcon} Notes</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
