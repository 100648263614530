import React, { useState, useEffect } from "react";
import { useStore } from "../store/Store";
import Modal from "./Modal";
import { useZxing } from "react-zxing";
import { elipsisIcon, trash, videoPause } from "../icons/Icons";
//import html2canvas from "html2canvas";
import "../App.css";
import { Link } from "react-router-dom";

const Scan = () => {
  const store = useStore();

  const [data, setData] = useState(store.articles);
  //const [readable, setReadable] = useState([]);
  const [pause, setPause] = useState(true);
  const [resultPoints, setResultPoints] = useState([]);
  const [highlight, setHighlight] = useState(false);
  const [boxHighlight, setBoxHighlight] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [tookSwatch, setTookSwatch] = useState({});

  function getReadableName(data) {
    const matName = data.split("|")[1];
    const swatchDetails = data.split(">")[1];
    if (matName && swatchDetails) {
      return matName.toUpperCase().replaceAll("-", " ") + " > " + swatchDetails;
    }
    return null;
  }

  const { ref } = useZxing({
    timeBetweenDecodingAttempts: 200,
    paused: pause,
    onResult(result) {
      //myStopFunction();
      setHighlight(false);
      setResultPoints(result.getResultPoints());
      if (
        result.getText().substring(0, 3) === "qr:" &&
        !data.includes(result.getText())
      ) {
        //setData([...data, result.getText()]);
        setData([...data, result.getText()]);
        setHighlight(true);
        setBoxHighlight(true);
      }
      setTimeout(() => {
        setResultPoints([]);
        setHighlight(false);
        setBoxHighlight(false);
      }, 100);
    },
    onError(error) {
      //console.log(store.articleNote);
      //console.log("why errors bitch", error);
    },
  });

  // const screenshot = async () => {
  //   const canvas = await html2canvas(ref);
  //   console.log(canvas)
  //   const image = canvas.toDataURL("image/png", 1.0);
  //   // download the image
  //   };

  useEffect(() => {
    store.addArticles(data);
  }, [data]);

  const tab = {
    fill: "none",
    stroke: highlight ? "#FF0000" : "#41d6e1",
    strokeWidth: 5,
    strokeLinecap: "round",
    strokeMiterlimit: 10,
  };

  const focusSquare = {
    fill: "none",
    stroke: boxHighlight ? "#FF0000" : "#41d6e1",
    strokeWidth: 2,
    strokeLinecap: "round",
    strokeMiterlimit: 10,
    strokeDasharray: "6,6",
  };

  function findArticleNote(i) {
    const myNote = store.articleNote.find((n) => n.item === i);
    //console.log("my Note", myNote);
    if (myNote !== undefined) {
      if (myNote.note || myNote.took || myNote.request) {
        return true;
      }
      return false;
    }
    return false;
  }

  const handleSelectedItem = (item) => {
    setModalShow(true);
    setModalItem(item);
  };

  const handleClearMaterialData = () => {
    store.removeAllArticles();
    setData([]);
  };

  return (
    <div className="main">
      {modalShow ? (
        <Modal
          setModalShow={setModalShow}
          modalItem={modalItem}
          tookSwatch={tookSwatch}
          //readable={readable}
          setTookSwatch={setTookSwatch}
        />
      ) : null}
      <div className="scanner">
        <video
          ref={ref}
          width="400"
          height="300"
          onCanPlay={() => setPause(false)}
        ></video>
        {pause ? (
          <div onClick={() => setPause(false)} className="pause-button">
            {videoPause}
          </div>
        ) : (
          <div className="pause-button" onClick={() => setPause(true)}></div>
        )}
        <svg
          id="overBox"
          x="0px"
          y="0px"
          viewBox="0 0 400 300"
          style={{
            position: "absolute",
            top: "0px",
            width: "400px",
            height: "300px",
          }}
        >
          <path
            id="shade"
            fill="#00000050"
            d="M0,0v300h400V0H0z M380.1,276c0,2.2-1.8,4-4,4H24c-2.2,0-4-1.8-4-4V24c0-2.2,1.8-4,4-4h352.1c2.2,0,4,1.8,4,4
	V276z"
          />
          <g id="frameTabs">
            <path style={tab} d="M20,70l0-46c0-2.2,1.8-4,4-4l46,0" />
            <path style={tab} d="M380,230v46c0,2.2-1.8,4-4,4h-46" />
            <path style={tab} d="M20,230l0,46c0,2.2,1.8,4,4,4h46" />
            <path style={tab} d="M380,70V24c0-2.2-1.8-4-4-4l-46,0" />
          </g>
          {resultPoints.length ? (
            <g style={{ transform: "scale(1.2)" }}>
              <polygon
                points={`
            ${resultPoints[0].x / 1.6}, ${resultPoints[0].y / 1.6}
            ${resultPoints[1].x / 1.6}, ${resultPoints[1].y / 1.6}
            ${resultPoints[2].x / 1.6}, ${resultPoints[2].y / 1.6}
            ${
              resultPoints[3]
                ? (resultPoints[3].x + 10) / 1.6
                : (resultPoints[2].x + 10) / 1.6
            }, ${
                  resultPoints[3]
                    ? (resultPoints[3].y + 10) / 1.6
                    : (resultPoints[2].y + 10) / 1.6
                }

            `}
                style={focusSquare}
              />
            </g>
          ) : null}
        </svg>
      </div>
      {/* <div className="button-small" onClick={handleScreenShot}>
        Screen capture
      </div> */}
      <div className="mid-text-area">
        {data.length !== 0 ? (
          <>
            <h4>
              {data.length} {data.length >= 2 ? "items" : "item"} scanned
            </h4>
            {data.length ? (
              <div className="clearList" onClick={handleClearMaterialData}>
                <span>{trash}</span>
              </div>
            ) : null}
          </>
        ) : (
          <h4>No items scanned</h4>
        )}
      </div>
      <div className="output">
        <ul className="article-list">
          {data
            ? data.map((item, i) => (
                <li key={item}>
                  {i + 1}
                  {". "}
                  {getReadableName(item)}
                  <div
                    className={
                      findArticleNote(i) ? "selectedItem active" : "selctedItem"
                    }
                  >
                    <span onClick={() => handleSelectedItem(i)}>
                      {elipsisIcon}
                    </span>
                  </div>
                </li>
              ))
            : "no results"}
        </ul>
        {data.length ? (
          <div className="router-link">
            <Link to="/note">Add Notes</Link>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Scan;
