import React from "react";
import { Routes, Route } from "react-router-dom";

import Scan from "./components/Scan";
import Note from "./components/Note";
import Error from "./components/Error";
import Complete from "./components/Complete";
import Landing from "./components/Landing";
import Menu from "./components/Menu";
import StoredMeetings from "./components/StoredMeetings";
import Mcs from "./components/Mcs";

function App() {
  return (
    <>
      <Menu />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/scan" element={<Scan />} />
        <Route path="/note" element={<Note />} />
        <Route path="/summary" element={<Complete />} />
        <Route path="/stored" element={<StoredMeetings />} />
        <Route path="/mcs" element={<Mcs />} />

        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
}

export default App;
